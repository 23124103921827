<div v-if="options">
    <treeselect
        v-model="selected"
        :multiple="multiple"
        :options="options"
        :disable-branch-nodes="disableBranchNodes"
        :placeholder="placeholder"
        :normalizer="normalizer"
        no-results-text="Не найдено"
    />
</div>
